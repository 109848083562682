// import axios from 'axios';
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import * as XLSX from "xlsx";
import { useTable } from "react-table";
import Images from "../../../asstes";
import { getViewCustomer } from "../Api/GlobalApi";
import { useQuery } from "react-query";
import { saveAs } from "file-saver";
import axios from "axios";
import PopupModal from "../popupmodal/PopupModal";
export default function ViewCustomerTable() {
  const navigate = useNavigate();
  const [searchTerm, setSearchTerm] = useState("");
  // const [filteredData, setFilteredData] = useState([]);

  const { data, isLoading, isError, refetch } = useQuery(
    "getViewCustomer",
    getViewCustomer
  );

  const [selectedCustomer, setSelectedCustomer] = useState(data);
  const [isOpen, setIsOpen] = useState(false);

  const [popupState, setPopupState] = useState({
    removeConfirm: false,
    deleteId: null,
    removeSucess: false,
  });

  // Function to close the modal
  const closeModal = () => {
    setIsOpen(false);
    setPopupState({
      removeConfirm: false,
      removeSucess: false,
      deleteId: null,
    });
  };
  const openDeleteModal = (customerId) => {
    setIsOpen(true);
    setPopupState({
      removeConfirm: true,
      deleteId: customerId,
    });
  };
  const deletetogglePopup = async (state) => {
    // console.log("DDSA:", state);

    try {
      await axios.delete(
        `https://api.2kvirtualworld.com/EMS/customers/delete/${state}`
      );
    } catch (error) {
      console.log("Error", error);
    }
    refetch();
    // setPopupOpen(false);
  };

  useEffect(() => {
    try {
      // Filter the data based on the customerId
      const values =
        data &&
        data?.data?.filter(
          (ele) => String(ele?.customerId) === String(selectedCustomer)
        );
      if (values?.length > 0) {
        // console.log("Matching customer found:", values);
        // Navigate to NewQuotation page with the matching data
        navigate("/AddCustomer", { state: values });
      } else {
        console.warn("No matching customer found.");
      }
    } catch (error) {
      console.error("Error during navigation:", error);
    }
  }, [data, selectedCustomer]);

  const columns = React.useMemo(
    () => [
      // { Header: "Select", accessor: "select" },
      // { Header: "S.No", accessor: "sno" },
      { Header: "Customer Id", accessor: "customerId" },
      { Header: "Company Name", accessor: "companyName" },
      { Header: "Client Name", accessor: "clientName" },
      { Header: "Phone Number", accessor: "phoneNumber" },
      { Header: "Designation", accessor: "designation" },
      { Header: "Date of Enquiry", accessor: "dateOfEnquiry" },
      { Header: "Tele Calling Base", accessor: "base" },
      { Header: "Need", accessor: "need" },
      { Header: "Description", accessor: "description" },
      { Header: "Tele Caller Name", accessor: "telecallerName" },
      { Header: "Tele Caller Number", accessor: "telecallerMobNo" },
      // { Header: "Mobile Number", accessor: "mobileNumber" },
      { Header: "Assign From", accessor: "assignFrom" },
      { Header: "Assign To", accessor: "assignTo" },
      { Header: "Pending From", accessor: "pendingFrom" },
      {
        Header: "Product Copy",
        accessor: "productCopy",
        Cell: ({ row }) => (
          <div>
            <button
              // onClick={() => deletetogglePopup(row?.original?.customerId)}
              // onClick={() => {
              //   setIsOpen(true);
              //   setPopupState({
              //     removeConfirm: true,
              //     deleteId: row.original.customerId,
              //   });
              // }}
              onClick={() => openDeleteModal(row.original.customerId)}
              style={{
                marginRight: "10px",
                cursor: "pointer",
                border: "none",
                background: "transparent",
              }}
            >
              🗑️
            </button>
            <button
              onClick={() => setSelectedCustomer(row?.original?.customerId)}
              style={{
                cursor: "pointer",
                border: "none",
                background: "transparent",
              }}
            >
              ✏️
            </button>
          </div>
        ),
      },
    ],
    []
  );

  const tabelcolums =
    (data &&
      data?.data?.map((item) => ({
        ...item,
        address: `${item?.flatNo}, ${item?.street}, ${item?.area}, ${item?.city}, ${item?.state} - ${item?.pincode}`,
        select: <input type="checkbox" />,
      }))) ||
    [];

  const filteredData =
    tabelcolums && tabelcolums
      ? tabelcolums &&
        tabelcolums?.filter(
          (item) =>
            item?.clientName
              ?.toLowerCase()
              .includes(searchTerm?.toLowerCase()) ||
            item?.customerId?.toLowerCase().includes(searchTerm?.toLowerCase())
        )
      : [];
  const tableInstance = useTable({ columns, data: filteredData });

  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
    tableInstance;

  const downloadExcel = () => {
    // Prepare empty data if there's no valid data to export
    const exportData =
      filteredData && filteredData?.length > 0 ? filteredData : data;

    const tableDatas =
      exportData && exportData?.length > 0
        ? exportData?.map((data, index) => ({
            "S.NO": index + 1,
            "CUSTOMER ID": data?.customerId || null,
            "COMPANY NAME": data?.companyName || null,
            "CLIENT NAME": data?.clientName || null,
            "MOBILE NUMBER": data?.phoneNumber || null,
            DESIGNATION: data?.designation || null,
            "DATE OF ENQUIRY": data?.dateOfEnquiry || null,
            NEED: data?.need || null,
            DESCRIPTION: data?.description || null,
            "TELE CALLER NAME": data?.telecallerName || null,
            "TELE CALLER NUMBER": data?.telecallerMobNo || null,
          }))
        : []; // Default to an empty array

    // Create a new workbook and add an empty sheet if there's no data
    const workbook = XLSX.utils.book_new();
    const tableWorksheet = XLSX.utils.json_to_sheet(
      tableDatas?.length > 0 ? tableDatas : [{}],
      {
        skipHeader: false,
      }
    );

    // Optionally set headers even if no data
    if (tableDatas?.length === 0) {
      XLSX.utils.sheet_add_aoa(tableWorksheet, [
        [
          "S.NO",
          "CUSTOMER ID",
          "COMPANY NAME",
          "CLIENT NAME",
          "MOBILE NUMBER",
          "DESIGNATION",
          "DATE OF ENQUIRY",
          "NEED",
          "DESCRIPTION",
          "TELE CALLER NAME",
          "TELE CALLER NUMBER",
        ],
      ]);
    }

    XLSX.utils.book_append_sheet(workbook, tableWorksheet, "Table Data");

    // Generate the Excel file
    const excelBuffer = XLSX.write(workbook, {
      bookType: "xlsx",
      type: "array",
    });

    // Save the Excel file
    const blob = new Blob([excelBuffer], { type: "application/octet-stream" });
    saveAs(blob, "View Customer.xlsx");
  };

  return (
    <>
      <div className="col-lg-12  view-customer-heading-block pt-3 px-3">
        <div className="customerSearchBlock">
          <input
            type="search"
            className="search-box"
            onChange={(e) => setSearchTerm(e.target.value)}
          />
          <div className="image-icon">
            <img
              src={Images?.searchIcon}
              alt="No-Image"
              style={{ width: "100%", height: "100%" }}
            />
          </div>
        </div>
        <div className="download-icon">
          <img
            src={Images?.downloadIcon}
            width="100%"
            height="100%"
            alt="No-Image"
            style={{ cursor: "pointer" }}
            onClick={() => {
            downloadExcel();
            }}
          />
        </div>
      </div>

      <div className="view-customer-table-container mx-3 mt-3">
        <div className="view-customer-table-block">
          <table className="studetailstable" cellPadding="10px">
            <thead>
              {headerGroups?.map((headerGroup) => (
                <tr {...headerGroup?.getHeaderGroupProps()}>
                  {headerGroup?.headers?.map((column) => (
                    <th
                      className="viewcustomerheader"
                      {...column?.getHeaderProps()}
                    >
                      {column?.render("Header")}
                    </th>
                  ))}
                </tr>
              ))}
            </thead>
            <tbody {...getTableBodyProps()}>
              {rows.length > 0 ? (
                rows.map((row) => {
                  prepareRow(row);
                  return (
                    <tr {...row.getRowProps()}>
                      {row.cells.map((cell) => (
                        <td
                          className="viewcustomerdata"
                          {...cell.getCellProps()}
                        >
                          {cell.render("Cell")}
                        </td>
                      ))}
                    </tr>
                  );
                })
              ) : (
                <tr>
                  <td
                    colSpan={columns.length}
                    style={{ textAlign: "center", padding: "10px" }}
                  >
                    No records found
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
      </div>
      <PopupModal
        isOpen={isOpen}
        onClose={closeModal}
        popupState={popupState}
        setPopupState={setPopupState}
        isLoading={isLoading}
        deleteHandler={deletetogglePopup}
        message="CUSTOMER HAS BEEN DELETED SUCCESSFULLY"
      />
    </>
  );
}
