import axios from "axios";
import React, { useState, useEffect } from "react";
import DatePicker from "react-datepicker";
import { Controller, useForm } from "react-hook-form";
import { useMutation } from "react-query";
import { useLocation, useNavigate } from "react-router";
import PopupModal from "../popupmodal/PopupModal";
// import asstes from "../../../assetes";
export default function AddSales() {
  const navigate = useNavigate();
  const { state } = useLocation();
  const [startDate, setStartDate] = useState(new Date());
  const {
    register,
    handleSubmit,
    control,
    setValue,
    formState: { errors },
  } = useForm();
  const [isOpen, setIsOpen] = useState(false);
  const [popupState, setPopupState] = useState({
    addData: false,
  });

  // Function to close the modal
  const closeModal = () => {
    setIsOpen(false);
    setPopupState({
      addData: false,
    });
  };

  const handleNavigate = () => {
    navigate("/viewsales");
    setIsOpen(false);
    setPopupState({
      addData: false,
    });
  };

  useEffect(() => {
    console.log("state",state);
    
    if (state) {
      setValue("invoiceType", state[0]?.invoiceType);
      setValue("genderTitle", state[0]?.genderTitle);
      setValue("customerId", state[0]?.customerId);
      setValue("customerName", state[0]?.customerName);
      setValue("companyName", state[0]?.companyName);
      setValue("flatNo", state[0]?.flatNo);
      setValue("street", state[0]?.street);
      setValue("area", state[0]?.area);
      setValue("city", state[0]?.city);
      setValue("pincode", state[0]?.pincode);
      setValue("state", state[0]?.state);
      setValue("startDate", state[0]?.startDate);
      setValue("description", state[0]?.description);
      setValue("paymentMode", state[0]?.paymentMode);
      setValue("paymentDate", state[0]?.paymentDate);
      setValue("projectAmount", state[0]?.projectAmount);
      setValue("cgst", state[0]?.cgst);
      setValue("sgst", state[0]?.sgst);
    }
  });

  const { mutate, isLoading, isError } = useMutation(
    (postData) =>
      axios.post(
        "https://api.2kvirtualworld.com/EMS/sales/postSales",
        postData
      ),
    {
      onSuccess: (data) => {
        // navigate("/viewemployeeTable");
        setIsOpen(true);
        setPopupState({
          addData: true,
        });
      },
    }
  );

  const updateData = useMutation(
    (postData) =>
      axios.put(
        `https://api.2kvirtualworld.com/EMS/employee/update/${state[0]?.employeeId}`,
        postData
      ),
    {
      onSuccess: (data) => {
        // navigate("/viewemployeeTable");
        setIsOpen(true);
        setPopupState({
          addData: true,
        });
      },
    }
  );
  const onSubmit = (data) => {
    console.log("data_form", data);
    const formValue = {
      genderTitle: data?.genderTitle,
      customerName: data?.customerName,
      companyName: data?.companyName,
      flatNo: data?.flatNo,
      street: data?.street,
      area: data?.area,
      city: data?.city,
      pincode: data?.pincode,
      state: data?.state,
      startDate: data?.startDate,
      paymentDate: data?.paymentDate,
      description: data?.description,
      projectAmount: data?.projectAmount,
      cgst: data?.cgst,
      sgst: data?.sgst,
      invoiceType: data?.invoiceType,
      paymentMode: data?.paymentMode,

      // genderTitle: data?.genderTitle,
      // customerName: data?.customerName,
      // companyName: data?.companyName,
      // flatNo: data?.flatNo,
      // street: data?.street,
      // area: data?.area,
      // city: data?.city,
      // pincode: data?.pincode,
      // state: data?.state,
      // startDate: data?.startDate,
      // paymentDate: data?.paymentDate,
      // description: data?.description,
      // projectAmount: data?.projectAmount,
      // cgst: data?.cgst,
      // sgst: data?.sgst,
      // invoiceType: data?.invoiceType,
      // paymentMode: data?.paymentMode,
    };
    console.log("formValue", formValue);
    mutate(formValue);
  };
  return (
    <>
      <div className="customercreationbg">
        <div className="customercreationFormheader">
          <form
            className="customercreationform"
            onSubmit={handleSubmit(onSubmit)}
          >
            <div className="customercontainer">
              <div className="customercreationheader">
                <div>
                  <label className="Customer_Id">Customer ID</label>
                  <input
                    className="inputeid"
                    type="text"
                    // placeholder="2KC00008"
                    {...register("customerID")}
                  ></input>
                </div>
                <div>
                  <label className="To_Customer_name"> To Customer name</label>
                  <input
                    type="text"
                    // placeholder="kumar"
                    className="inputeid"
                    {...register("customerName")}
                  ></input>
                </div>
                <div>
                  <label className="Flat_No"> Flat No</label>
                  <input
                    type="text"
                    // placeholder="ND:99/39"
                    className="inputeid"
                    {...register("flatNo")}
                  ></input>
                </div>
                <div>
                  <lable className="Area_Village">Area/Village</lable>
                  <input
                    type="text"
                    // placeholder="SAIDAPET"
                    className="inputeid"
                    {...register("area")}
                  ></input>
                </div>
                <div>
                  <label className="Pin_code">Pin code</label>
                  <input
                    type="text"
                    // placeholder="600015"
                    className="inputeid"
                    {...register("pincode")}
                  ></input>
                </div>
                <div>
                  <label className="Start_Date">Start Date</label>
                  <Controller
                    control={control}
                    name="startDate"
                    render={({ field: { onChange, value } }) => (
                      <DatePicker
                        // placeholderText="DOB"
                        showYearDropdown
                        selected={value}
                        onChange={(date) => {
                          onChange(date);
                          setStartDate(date);
                        }}
                        className="inputeid"
                      />
                    )}
                  />
                  {/* <input
                  type="text"
                  // placeholder="DD/MM/YYYY"
                  className="inputeid"
                  {...register("statedate")}
                ></input> */}
                </div>
                <div>
                  <label className="Description">Description</label>
                  <input
                    type="text"
                    // placeholder="payment of billing Software web application development (with server)"
                    className="inputeid"
                    {...register("description")}
                  ></input>
                </div>
                <div>
                  <label className="CGST ">CGST 9%</label>
                  <input
                    type="text"
                    // placeholder="55"
                    className="inputeid"
                    {...register("cgst")}
                  ></input>
                </div>
                <div>
                  <label className="Type of Invoice">Type of Invoice</label>
                  <input
                    type="text"
                    // placeholder="None"
                    className="inputeid"
                    {...register("invoiceType")}
                  ></input>
                </div>
              </div>
              <div className="customercreationheader">
                <div>
                  <label className="MrMrs">Mr/Mrs</label>

                  <select
                    {...register("genderTitle")}
                    name="genderTitle"
                    className="inputeid"
                  >
                    <option value=""> </option>
                    <option value="Mr">Mr</option>
                    <option value="Ms">Ms</option>
                    <option value="Mrs">Mrs</option>
                    <option value="Other">Other</option>
                  </select>
                </div>
                <div>
                  <label className="Company name">Company name</label>
                  <input
                    type="text"
                    // placeholder="BORA DIAGNOSTIC CENTRE"
                    className="inputeid"
                    {...register("companyName")}
                  ></input>
                </div>
                <div>
                  <label className="Street">Street</label>
                  <input
                    type="text"
                    // placeholder="SUBRAMANI MUDALI STREET"
                    className="inputeid"
                    {...register("street")}
                  ></input>
                </div>
                <div>
                  <label className="City">City</label>
                  <input
                    type="text"
                    // placeholder="CHENNAI"
                    className="inputeid"
                    {...register("city")}
                  ></input>
                </div>
                <div>
                  <label className="State">State</label>
                  <input
                    type="text"
                    // placeholder="TAMILNADU"
                    className="inputeid"
                    {...register("state")}
                  ></input>
                </div>
                <div>
                  <label className="payment Date">payment Date</label>
                  <Controller
                    control={control}
                    name="paymentDate"
                    render={({ field: { onChange, value } }) => (
                      <DatePicker
                        // placeholderText="DOB"
                        showYearDropdown
                        selected={value}
                        onChange={(date) => {
                          onChange(date);
                          setStartDate(date);
                        }}
                        className="inputeid"
                      />
                    )}
                  />
                  {/* <input
                  type="text"
                  // placeholder="DD/MM/YYYY"
                  className="inputeid"
                  {...register("paymentDate")}
                ></input> */}
                </div>
                <div>
                  <label className="Project Amount">Project Amount</label>
                  <input
                    type="text"
                    // placeholder="20000"
                    className="inputeid"
                    {...register("projectAmount")}
                  ></input>
                </div>
                <div>
                  <label className="SGST 9%">SGST 9%</label>
                  <input
                    type="text"
                    // placeholder="55"
                    className="inputeid"
                    {...register("sgst")}
                  ></input>
                </div>
                <div>
                  <label className="SGST 9%">payment Mode</label>
                  <input
                    type="text"
                    // placeholder="None"
                    className="inputeid"
                    {...register("paymentMode")}
                  ></input>
                </div>
                <div className="btn_group dropend d-flex ">
                  <button type="submit" className="btn btn-primary btn-custom ">
                    submit
                  </button>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
      <PopupModal
        isOpen={isOpen}
        onClose={closeModal}
        popupState={popupState}
        message="NEW SALES HAS BEEN ADDED SUCCESSFULLY"
        onDone={handleNavigate}
        updateMessage="SALES HAS BEEN UPDATED SUCCESSFULLY"
        stateID={state && state[0]?.customerId}
      />
    </>
  );
}
