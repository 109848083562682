
import React, { useState } from "react";
import Images from "../../asstes";
import { useNavigate } from "react-router";
import { useForm, Controller } from "react-hook-form";
import InputComponent from "./InputComponent/InputComponent";
import { useMutation } from "react-query";
import axios from "axios";
import { useDispatch } from "react-redux";
import { loginSuccess, employeeDetails } from "../Redux/Actions";
import { toBeRequired } from "@testing-library/jest-dom/dist/matchers";
import openicon from "../../../src/asstes/png/open.png";
import closeicon from "../../../src/asstes/png/closei.png";
export default function LoginPage() {
  const dispatch = useDispatch();
  const [ischeck, setIsCheck] = useState(false);
  const [passwordIconShow,setPasswordIconShow] = useState(false)
 console.log("passwordIconShow",passwordIconShow);

  const navigation = useNavigate();
  const { register, handleSubmit, handleReset, reset, control } = useForm();
  const { mutate, isLoading, isError } = useMutation(
    (postData) =>
      axios.post("https://api.2kvirtualworld.com/EMS/employee/login", postData),
    {
      onSuccess: (data) => {
        dispatch(employeeDetails(data && data?.data?.employee));
        dispatch(loginSuccess());
        navigation("/");
      },
    }
  );
  const onSubmit = (data) => {
    mutate(data);
  };
  return (
    <div>
      <div className="overal-container">
        <div className="container">
          <div className="logo-container">
            <img src={Images.logoImg} alt="logo" />
          </div>
        </div>
        <h1 style={{ color: "#7a1cac" }}>
          EMPLOYEE MANAGEMENT
          <br />
          SYSTEM
        </h1>
      </div>

      <div className="form-container">
        <form className="formcontainer" onSubmit={handleSubmit(onSubmit)}>
          <div className="email-container">
            <Controller
              control={control}
              name="loginEmail"
              render={({ field }) => (
                <input
                  {...field}
                  type="email"
                  placeholder="user Name"
                  className="login-input-field"
                />
              )}
            />

            <img src={Images.emailIcon} className="email-icon" />
          </div>

          <div>
            <div className="pass-container">
              <Controller
                control={control}
                name="loginPassword"
                render={({ field }) => (
                  <input
                    {...field}
                    type={passwordIconShow ? "text" : "password"}
                    placeholder="Password"
                    className="login-input-field"
                  />
                )}
              />
              { passwordIconShow ? <img onClick={()=>{
                setPasswordIconShow(false)
              }} style={{height:"25 px"}} src={openicon } className="pass-icon" /> : <img onClick={()=>{
                setPasswordIconShow(true)
              }} style={{height:"25px"}} src={closeicon } className="pass-icon" />}
             
              
            </div>
            
          </div>

          <div className="tick">
            <div className="check">
              <input
                type="checkbox"
                id="remember"
                className="check-box"
                value={ischeck}
                onClick={() => setIsCheck(!ischeck)}
              />
              <label for="remember">Remember Me</label>
            </div>
            <button type="submit" className="login-button">
              Login
            </button>
          </div>
        </form>
      </div>
    </div>
  );
}
